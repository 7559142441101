<template>
  <div>
    <div class="tile">
      <div class="row">
        <div class="col-sm-6 mb-sm-0 mb-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">VPN Credentials</h5>
              <hr />
              <div class="card-text">
                <div>
                  <span>Username:</span>
                  <span class="badge bg-light text-dark">{{
                    userLoggedIn.vpnUser ? userLoggedIn.vpnUser : "-"
                  }}</span>
                </div>
                <div>
                  <span> Password:</span
                  ><span class="badge bg-light text-dark">{{
                    userLoggedIn.vpnPassword ? userLoggedIn.vpnPassword : "-"
                  }}</span>
                </div>
                <div>
                  <span>PC IP:</span>
                  <span class="badge bg-light text-dark">{{
                    userLoggedIn.pcIP ? userLoggedIn.pcIP : "-"
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 mb-sm-0 mb-4">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title">VPN Client</h5>
              <hr />
              <div class="card-text">
                <a
                  href="https://openvpn.net/downloads/openvpn-connect-v3-windows.msi"
                  class="btn btn-primary mt-4"
                  download
                  >Download VPN Client</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tile vpn-instructions">
      <h5 class="tile-title mb-3">Instructions</h5>
      <div class="row">
        <ol class="col-lg-3 mb-lg-0 mb-4 list-group list-group-numbered">
          <li class="list-group-item">
            <a
              href="https://81.4.163.54:4443/userportal/webpages/myaccount/login.jsp"
              target="_blank"
              >Go to portal</a
            >
          </li>
          <li class="list-group-item">Click ADVANCED -> PROCEED if prompted</li>
          <li class="list-group-item">Login with your credentials</li>
          <li class="list-group-item">
            Click <b> Download configuration for other OS</b>
          </li>
          <li class="list-group-item">Open (Double click) downloaded file</li>
        </ol>
        <div class="col-lg-9">
          <div class="row">
            <div class="col-md-4">
              <li
                class="list-group-item image ms-lg-0 ms-sm-5 ms-4 mb-lg-0 mb-4"
              >
                <span class="me-3">6.</span>
                <img src="../assets/img/vpn/1.png" class="w-75" alt="" />
              </li>
            </div>
            <div class="col-md-4">
              <li
                class="list-group-item image ms-lg-0 ms-sm-5 ms-4 mb-lg-0 mb-4"
              >
                <span class="me-3">7.</span>
                <img src="../assets/img/vpn/2.png" class="w-75" alt="" />
              </li>
            </div>
            <div class="col-md-4">
              <li
                class="list-group-item image ms-lg-0 ms-sm-5 ms-4 mb-lg-0 mb-4"
              >
                <span class="me-3">8.</span>
                <img src="../assets/img/vpn/3.png" class="w-75" alt="" />
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getters } from "../store";
export default {
  computed: {
    userLoggedIn() {
      return getters.userLoggedIn();
    },
  },
};
</script>
